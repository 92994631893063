// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../components/Layout"
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq";
import Banner from "../../imgs/bg_cauciones.jpg";
import IframeVIdeo from "../../components/iframe_video/iframe_video";
import faqjson from "../../misc/cauciones_related/faq.json";
import slick_items from "../../misc/cauciones_related/slick_items_cauciones.json";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery, Link} from "gatsby";

const pageTitle = 'Invertí en Cauciones de forma rápida y segura | Balanz';
const pageDesc = 'Conocé qué son las cauciones y cómo invertir en Cauciones. Contamos con la mejor app de inversión y 20 años de trayectoria. Hacé crecer tus ahorros.';

let text = `Creemos que existen tantas inversiones como inversores, <br/><span class='highlightsbold'> te presentamos Cauciones.</span>`;

const CaucionesPage = () => {
    const [xs, setXs] = useState(false);
    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 11}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });

    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

  const slickSettings = {
      centerMode: false,
      slidesToShow: 5,
      dots: true,
      arrows: false,
      responsive: [
          {
              breakpoint: 2920,
              settings:"unslick"
          },
          {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
          },
          {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
          },
          {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          }
      ]
  };
    useEffect(() => {
        apiRequest
            .get("/v1/faq/11")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })
                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
  const page = (
    <>
        <Helmet>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Qué es una Caución Bursátil?",
                        "description": "Es una operación financiera garantizada con valores negociables de corto plazo que van desde 1 a 120 días en pesos y en dólares.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=1LfX1q7xGqk",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/cauciones/",
                        "embedUrl": "https://balanz.com/inversiones/cauciones/"
                    }
                `}
            </script>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Cómo invertir en Cauciones?",
                        "description": "Es muy fácil, lo hacés directamente desde nuestra plataforma.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=K5LP6LwwIq4",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/cauciones/",
                        "embedUrl": "https://balanz.com/inversiones/cauciones/"
                    }
                `}
            </script>
        </Helmet>
        <ProductBanner
          bannerContainerClassName="bannerCauciones"
          bannerTrasparecy="bannerMask"
          productTitle="Cauciones"
          bannerImage={Banner}
          bannerImageClassName="caucionesBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerText1"
          bannerImageMobile={Banner}
          mobile={false}
        />
        <section className="fullwidth product-description">
            <Container>
                <Row className="">
                    <h2>¿Qué es una Caución Bursátil?</h2>
                </Row>
            </Container>
            <div className="bg-lt-grey">
                <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Es una operación financiera garantizada con valores negociables de corto plazo que van desde 1 a 120 días en pesos y en dólares.<br />Existen dos tipos de operaciones: colocadoras y tomadoras. En la primera, el colocador le presta dinero al tomador y obtiene una tasa de interés. En la segunda, el tomador pide dinero prestado y entrega en garantía cierta cantidad de valores negociables que serán valuados por el mercado. Al vencimiento, el tomador devuelve el capital y paga los intereses devengados al colocador. Se trata de una operación a plazo firme con garantía del mercado.
                            </p>
                            <div className="pt-4 text-center">
                                <Button id="cauciones_invertir_1" variant="secondary" text="Invertir en Cauciones" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row className="py-5 justify-content-center">
                    <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                        <IframeVIdeo
                            src="https://www.youtube.com/embed/A1mB_rCdelY"
                            resolutionClass={null}
                        />
                    </Col>
                </Row>
                <Row className="gx-5 gy-4 pt-3 pt-sm-2 pt-md-3 pt-md-4 pt-lg-5">
                    <Col xs={12}>
                        <p className="product-text">
                            Es una inversión similar al plazo fijo, pero cuenta con mayor flexibilidad en los plazos. También tiene un interés preestablecido con lo cual el colocador conoce de antemano la rentabilidad de la inversión.
                        </p>
                    </Col>
                </Row>
                {/*<Row>
                    <div className="text-center pt-4 p-lg-3">
                        <Button variant="secondary" text="Ver Cauciones"/>
                    </div>
                </Row>*/}
            </Container>
        </section>
        <section className="fullwidth razones cauciones">
             <Container>
                 <Row>
                    <h2 className="mt-2">5 razones para invertir en Cauciones</h2>

                  <SlickProduct
                    slickSettings={slickSettings}
                    list={slick_items}
                  />
                </Row>
             </Container>
         </section>
        <section className="fullwidth como-operar">
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={6}>
                    <h2 className="mt-2">¿Cómo invertir en Cauciones?</h2>
                      <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2 mb-lg-5">
                          Es muy fácil, lo hacés directamente desde nuestra
                          plataforma. <br/>Te recomendamos conocer tu perfil de
                          inversor y analizar junto con tu asesor, si es necesario,
                          cuál es la mejor opción para vos.</p>
                      <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                          <Button id="cauciones_invertir_2" variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir en Cauciones" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                      </div>

                  </Col>
                  <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                    <IframeVIdeo
                      src="https://www.youtube.com/embed/K5LP6LwwIq4"
                      resolutionClass={null}
                    />
                  </Col>
                </Row>
                 {/*<Row className="d-block d-md-none">
                     <div className="text-center pt-4 p-lg-3">
                         <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                     </div>
                 </Row>*/}
             </Container>
        </section>
        <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en Cauciones? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>
        <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'cauciones-faq'} />
                </Row>
              </Container>
        </section>
    </>
  )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"cauciones"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default CaucionesPage;
